import React from 'react';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { carousel } from './testimonies-carousel.module.scss';

const indicatorStyles = {
  background: 'rgba(72, 93, 112, 0.5)',
  width: 36,
  height: 7,
  display: 'inline-block',
  margin: '0 9px',
  cursor: 'pointer'
};

const TestimoniesCarousel = ({ children }) => {
  return (
    <Carousel
      showThumbs={false}
      showArrows={false}
      showStatus={false} 
      interval={5000}
      transitionTime={500}
      swipeScrollTolerance={1}
      className={carousel}
      renderIndicator={(onClickHandler, isSelected, index) => {
        if (isSelected) {
          return (
              <button
                style={{ ...indicatorStyles, background: '#485D70' }}
                aria-label="Change testimonie"
              />
          );
        }
        return (
          <button
            style={indicatorStyles}
            onClick={onClickHandler}
            onKeyDown={onClickHandler}
            value={index}
            key={index}
            aria-label="Change testimonie"
            tabIndex={0}
          />
        );
      }}
    >{children}</Carousel>
  );
};

export default TestimoniesCarousel;