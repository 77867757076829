import React, { useRef, useEffect } from 'react';
import lottie from 'lottie-web';

const Animation = ({ animationUrl, styleClass }) => {
  const container = useRef(null);
  let animation = useRef(null);

  const checkVisible = (elm) => {
    var rect = elm.getBoundingClientRect();
    var viewHeight = Math.max(document.documentElement.clientHeight, window.innerHeight);
    return !(rect.bottom < 0 || rect.top - viewHeight >= 0);
  }

  const playIfVisible = () => {
    checkVisible(container.current) && animation.current.play();
  };

  useEffect(() => {
    animation.current = lottie.loadAnimation({
      container: container.current,
      renderer: 'svg',
      autoplay: false,
      loop: false,
      path: animationUrl
    });

    document.addEventListener('scroll', playIfVisible)

    return () => {
      animation.current.destroy();
      document.removeEventListener('scroll', playIfVisible);
    }
  });

  return (
    <div
      ref={container}
      className={styleClass}
      onClick={() => {
        animation.current.goToAndPlay(0);
      }}
      style={{cursor: 'pointer', outline: 'none', position: 'relative', zIndex: '5'}}
      role="button"
      aria-label="animation"
      tabIndex={0}
    ></div>
  );
};

export default Animation;